<script>
  import LogoVisa from '@/images/logos/LogoVisa.svg'
  import LogoMastercard from '@/images/logos/LogoMastercard.svg'
  import LogoAmex from '@/images/logos/LogoAmex.svg'
  import LogoDiscover from '@/images/logos/LogoDiscover.svg'

  /**
   * A horizontal stack of small credit card logos.
   */
  export default {
    components: {
      LogoVisa,
      LogoMastercard,
      LogoAmex,
      LogoDiscover,
    },
  }
</script>

<template>
  <div class="flex">
    <LogoVisa class="w-30px h-5 rounded-sm border border-gray-active bg-white" />
    <LogoMastercard class="ml-1 w-30px h-5 rounded-sm border border-gray-active bg-white" />
    <LogoAmex class="ml-1 w-30px h-5 rounded-sm border border-gray-active bg-white" />
    <LogoDiscover class="ml-1 w-30px h-5 rounded-sm border border-gray-active bg-white" />
  </div>
</template>
